import { apiAxiosV1 } from '@/configs/axios';
import { IncentBanner } from '@/model/Banner';

export const BannerService = {
  async getBanners(country) {
    const url = `/banner/list`;
    const res = await apiAxiosV1.get(url);
    const banners =
      country === 'IN'
        ? [
            {
              banner_id: 'yt-banner',
              title: {
                en: 'Sikka Video',
                hi: 'Sikka Video',
              },
              body: {
                en: '',
                hi: '',
              },

              data: {
                video_url:
                  'https://youtu.be/7Si92dmaxqI?si=s-RkhmPUoNsWUZFk&t=133',
                entity_type: 'video',
                entity_id: '',
                title: {
                  en: 'Sikka Video',
                  hi: 'Sikka Video',
                },
                description: {
                  en: '',
                  hi: '',
                },
              },
            },
          ].concat([...res?.data])
        : [...res?.data];

    return IncentBanner.fromAll(banners);
  },
};
