// [
//     {
//       "banner_id": 104,
//       "title": {
//         "en": "Offers",
//         "hi": "Offers"
//       },
//       "body": {
//         "en": "offers karo",
//         "hi": "offers karo"
//       },
//       "image_url": "https://storage.googleapis.com/incent-app-bucket/banners/fd96a203-9231-4b09-b017-2e61f162c43e.png",
//       "data": {
//         "entity_type": "offer",
//         "entity_id": "12012",
//         "image_url": "https://storage.googleapis.com/incent-app-bucket/banners/fd96a203-9231-4b09-b017-2e61f162c43e.png",
//         "title": {
//           "en": "Offers",
//           "hi": "Offers"
//         },
//         "description": {
//           "en": "offers+karo",
//           "hi": "offers+karo"
//         }
//       }
//     },
//     {
//       "banner_id": 99,
//       "title": {
//         "en": "",
//         "hi": ""
//       },
//       "body": {
//         "en": "",
//         "hi": ""
//       },
//       "image_url": "https://storage.googleapis.com/incent-app-bucket/banners/6db4a7bd-b3f0-4e2e-9d22-f5a588394c72.png",
//       "data": {
//         "entity_type": "spin_wheel",
//         "entity_id": "4850",
//         "image_url": "https://storage.googleapis.com/incent-app-bucket/banners/6db4a7bd-b3f0-4e2e-9d22-f5a588394c72.png",
//         "title": {
//           "en": "",
//           "hi": ""
//         },
//         "description": {
//           "en": "",
//           "hi": ""
//         }
//       }
//     },
//     {
//       "banner_id": 97,
//       "title": {
//         "en": "",
//         "hi": ""
//       },
//       "body": {
//         "en": "",
//         "hi": ""
//       },
//       "image_url": "https://storage.googleapis.com/incent-app-bucket/banners/98f675aa-238f-45e0-b6db-8773bfe2ccce.png",
//       "data": {
//         "entity_type": "url",
//         "entity_id": "https://www.gtav.com",
//         "image_url": "https://storage.googleapis.com/incent-app-bucket/banners/98f675aa-238f-45e0-b6db-8773bfe2ccce.png",
//         "title": {
//           "en": "",
//           "hi": ""
//         },
//         "description": {
//           "en": "",
//           "hi": ""
//         }
//       }
//     },
//     {
//       "banner_id": 117,
//       "title": {
//         "en": "",
//         "hi": ""
//       },
//       "body": {
//         "en": "",
//         "hi": ""
//       },
//       "image_url": "https://storage.googleapis.com/incent-app-bucket/banners/a4091bb9-ca89-4dd2-b4bf-c62d599d5693.png",
//       "data": {
//         "entity_type": "contest",
//         "entity_id": "",
//         "image_url": "https://storage.googleapis.com/incent-app-bucket/banners/a4091bb9-ca89-4dd2-b4bf-c62d599d5693.png",
//         "title": {
//           "en": "",
//           "hi": ""
//         },
//         "description": {
//           "en": "",
//           "hi": ""
//         }
//       }
//     },
//     {
//       "banner_id": 102,
//       "title": {
//         "en": "",
//         "hi": ""
//       },
//       "body": {
//         "en": "",
//         "hi": ""
//       },
//       "image_url": "https://storage.googleapis.com/incent-app-bucket/banners/c9c33837-6bc5-45bd-ac3e-101396d82e0e.png",
//       "data": {
//         "entity_type": "referral",
//         "entity_id": "4850",
//         "image_url": "https://storage.googleapis.com/incent-app-bucket/banners/c9c33837-6bc5-45bd-ac3e-101396d82e0e.png",
//         "title": {
//           "en": "",
//           "hi": ""
//         },
//         "description": {
//           "en": "",
//           "hi": ""
//         }
//       }
//     },
//     {
//       "banner_id": 101,
//       "title": {
//         "en": "",
//         "hi": ""
//       },
//       "body": {
//         "en": "",
//         "hi": ""
//       },
//       "image_url": "https://storage.googleapis.com/incent-app-bucket/banners/933f6ce9-6009-4b9e-bfa7-221017cf13ce.png",
//       "data": {
//         "entity_type": "wallet",
//         "entity_id": "4850",
//         "image_url": "https://storage.googleapis.com/incent-app-bucket/banners/933f6ce9-6009-4b9e-bfa7-221017cf13ce.png",
//         "title": {
//           "en": "",
//           "hi": ""
//         },
//         "description": {
//           "en": "",
//           "hi": ""
//         }
//       }
//     },
//     {
//       "banner_id": 100,
//       "title": {
//         "en": "",
//         "hi": ""
//       },
//       "body": {
//         "en": "",
//         "hi": ""
//       },
//       "image_url": "https://storage.googleapis.com/incent-app-bucket/banners/7a705528-9d1e-4784-bf6d-f9",
//       "data": {
//         "entity_type": "daily_reward",
//         "entity_id": "4850",
//         "image_url": "https://storage.googleapis.com/incent-app-bucket/banners/7a705528-9d1e-4784-bf6d-f9",
//         "title": {
//           "en": "",
//           "hi": ""
//         },
//         "description": {
//           "en": "",
//           "hi": ""
//         }
//       }
//     },
//     {
//       "banner_id": 98,
//       "title": {
//         "en": "",
//         "hi": ""
//       },
//       "body": {
//         "en": "",
//         "hi": ""
//       },
//       "image_url": "https://storage.googleapis.com/incent-app-bucket/banners/be448e73-05ca-42ab-8aba-0e4b28db5420.png",
//       "data": {
//         "entity_type": "offer",
//         "entity_id": "4850",
//         "image_url": "https://storage.googleapis.com/incent-app-bucket/banners/be448e73-05ca-42ab-8aba-0e4b28db5420.png",
//         "title": {
//           "en": "",
//           "hi": ""
//         },
//         "description": {
//           "en": "",
//           "hi": ""
//         }
//       }
//     }
//   ]
export class IncentBanner {
  constructor(obj) {
    this.bannerId = obj.bannerId;
    this.appId = obj.appId;
    this.entityId = obj.entityId;
    this.entityType = obj.entityType;
    this.imageUrl = obj.imageUrl;
    this.status = obj.status;
    this.videoUrl = obj.videoUrl;
  }

  static INCENT_BANNER_TYPES = [
    {
      id: 'url',

      display: 'URL',
    },
    {
      id: 'offer',

      display: 'Offer',
    },
    {
      id: 'spin_wheel',

      display: 'Spin Wheel',
    },
    {
      id: 'daily_reward',

      display: 'Daily Reward',
    },
    {
      id: 'wallet',

      display: 'Wallet',
    },
    {
      id: 'referral',

      display: 'Referral',
    },
    {
      id: 'contest',

      display: 'Contest',
    },
  ];
  isUrl() {
    return this.entityType === 'url';
  }
  isOffer() {
    return this.entityType === 'offer';
  }
  isSpinWheel() {
    return this.entityType === 'spin_wheel';
  }
  isDailyReward() {
    return this.entityType === 'daily_reward';
  }
  isWallet() {
    return this.entityType === 'wallet';
  }
  isReferral() {
    return this.entityType === 'referral';
  }
  isContest() {
    return this.entityType === 'contest';
  }
  isVideo() {
    return this.entityType === 'video';
  }

  static statusMap = {
    ACTIVE: { id: 'ACTIVE', display: 'Active', variant: 'success' },
    INTERNAL_PAUSED: {
      id: 'INTERNAL_PAUSED',
      display: 'Internal Paused',
      variant: 'warning',
    },
    PAUSED: { id: 'PAUSED', display: 'Paused', variant: 'warning' },
  };

  isActive() {
    return this.status === 'ACTIVE';
  }
  isInternalPaused() {
    return this.status === 'INTERNAL_PAUSED';
  }
  isPaused() {
    return this.status === 'PAUSED';
  }
  getIncentEntyTypeDisplay() {
    return (
      IncentBanner.INCENT_BANNER_TYPES.find(type => type.id === this.entityType)
        ?.display || 'N/A'
    );
  }
  static from(obj) {
    if (!obj) return null;
    return new IncentBanner({
      bannerId: obj.banner_id,
      appId: obj.banner_app_id,

      entityId: obj.data.entity_id,
      entityType: obj.data.entity_type,
      imageUrl: obj.image_url,

      status: obj.status,
      videoUrl: obj.data.video_url,
    });
  }
  static fromAll(arr) {
    if (!arr?.length) return null;
    return arr.map(IncentBanner.from);
  }
}
